<template>
  <v-app>
    <dashboard-core-app-bar />

    <dashboard-core-drawer />

    <dashboard-core-view />
  </v-app>
</template>

<script>
  import User from "@/models/User";
  import Country from "@/models/Country";
  import ShipperAccount from "@/models/ShipperAccount";

  export default {
    name: 'DashboardIndex',

    components: {
      DashboardCoreAppBar: () => import('./components/core/AppBar'),
      DashboardCoreDrawer: () => import('./components/core/Drawer'),
      DashboardCoreView: () => import('./components/core/View'),
    },

    created() {
      User.api().get('/auth/users/me/').then(() => {
        // Make sure user is authenticated, and token had been refreshed if need
        Country.api().get('/api/v1/locations/countries/')
        ShipperAccount.api().get('/api/v1/couriers/shipper_accounts/')
      })
    },

    data: () => ({
      expandOnHover: false,
    }),
  }
</script>
